import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import {Button} from 'gatsby-theme-material-ui'

import Layout from '../components/layout'
import { Typography, Box, Container, Paper, Grid } from '@material-ui/core'

const TecajeviPage = ({ data }) => {
    
  const image = data.allFile.nodes[0]
  return (
    <Layout fixed title="Tečajevi stranih jezika">
        
      <Grid container spacing={4} alignItems="center" justifyItems='center'>
        
        <Grid item md={6}>
          
        <Typography paragraph variant="h3" color="primary" align="left">
        Tečajevi stranih jezika
        </Typography><br/>
          <Typography paragraph variant="body1">
            <p>Tečajevi se odvijaju dva puta tjedno po jedan školski sat (45 minuta), a vrijeme odvijanja ovisi o
            dogovoru s profesorom i polaznikom. Svaki tečaj traje 60 sati, odnosno dva semestra. </p>
            <p>Za starije polaznike nudimo ubrzane tečajeve od 30 sati, dva puta tjedno, a po dogovoru i tri puta tjedno u
            trajanju od 60 minuta.</p>
            <p>Nakon završetka predavanja polaznici polažu ispit i stječu stupanj znanja ( A1,
            A2, B1..C2) i ovjerenu svjedodžbu.</p>
            <br/>
            
            
          </Typography>
          
          <Grid container spacing={3}>
              <Grid item>
                <Button to="/cjenik#tecajevi" color="secondary" variant="contained">Cjenik tečajeva</Button>
              </Grid>
              <Grid item>
                <Button to="/kontakt" color="primary" variant="contained">Kontaktirajte nas!</Button>
              </Grid>
              
          </Grid>
          
            

        </Grid>

        
            <Grid item  md={6} xs={8} >
              <Img
        fluid={image.childImageSharp.fluid}
        //style={{height:"60%"}}
      />
            </Grid>

              
      </Grid>
        

    </Layout>
  )
}
export const query = graphql`

    query {
        allFile(filter: { relativePath: { eq: "gloria/polica_1.jpg" } }) {
          nodes {
            childImageSharp {
              fluid(
                maxWidth: 1200
                srcSetBreakpoints: [200, 340, 480, 900]
                quality: 80
              ) {
                ...GatsbyImageSharpFluid
                originalName
                aspectRatio
              }
            }
          }
        }
      }

`

export default TecajeviPage
